import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import Image from "gatsby-image";
import { Trans } from "gatsby-plugin-react-i18next";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo/seo";

export const pageQuery = graphql`
  query($language: String!) {
    media_1: file(relativePath: { eq: "graphic/images-graphic-1.png" }) {
      ...fluidImage
    }
    media_2: file(relativePath: { eq: "graphic/images-graphic-2.png" }) {
      ...fluidImage
    }
    media_31: file(relativePath: { eq: "graphic/images-graphic-3-1.png" }) {
      ...fluidImage
    }
    media_32: file(relativePath: { eq: "graphic/images-graphic-3-2.png" }) {
      ...fluidImage
    }
    media_33: file(relativePath: { eq: "graphic/images-graphic-3-3.png" }) {
      ...fluidImage
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Graphic = ({ data }) => {
  const shortDescription =
    "El diseño gráfico está en todas partes a nuestro alrededor, y nosotros lo tenemos en cuenta";

  const description = [
    "El diseño gráfico está en todas partes a nuestro alrededor: logotipos y marcas comerciales, publicidad, empaquetado de productos... Por eso es tan importante prestar atención al detalle en el diseño de estos elementos, que pasan por las manos de tanta gente. A través del diseño, podemos transmitirle al cliente aspectos que serían difíciles de comunicar mediante palabras, como la personalidad de nuestra marca.",
  ];

  const textInsert =
    "Nos gusta dar rienda suelta a nuestra creatividad, por lo que también hacemos trabajos para particulares: si necesitas una felicitación, cartel, póster o ilustración, ¡no dudes en ponerte en contacto!";

  const [index, setIndex] = useState(0);

  const banners = [
    data.media_31.childImageSharp.fluid,
    data.media_32.childImageSharp.fluid,
    data.media_33.childImageSharp.fluid,
  ];

  useEffect(() => {
    const interval = setInterval(
      () => setIndex((i) => (i + 1) % banners.length),
      5000
    );
    return () => {
      clearInterval(interval);
    };
  }, [banners.length]);

  return (
    <Layout pagename="graphic_services">
      <SEO title="Diseño gráfico" description={shortDescription} />
      <div className="page_content">
        <div className="service__text">
          <h3 className="service__title">
            <Trans>Diseño gráfico</Trans>
          </h3>
          {description.map((paragraph) => (
            <p className="service__description">
              <Trans>{paragraph}</Trans>
            </p>
          ))}
        </div>
        <div className="service__media">
          <div className="media_group">
            <Image fluid={data.media_1.childImageSharp.fluid} />
          </div>
          <div className="media_group media_group--stacked">
            <Image fluid={banners[index]} />
            <div className="media_group__text_insert">
              <Trans>{textInsert}</Trans>
            </div>
            <Image fluid={data.media_2.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Graphic;
